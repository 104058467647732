import Icon from "@components/Icon";
import {IconName} from "@services/types";
import React, {useEffect} from "react";

import {noOp} from "../../../utils/noOp";
import {ButtonStatus} from "./constants";
import {getAsyncButtonStyle} from "./styles";
import {ButtonSize, ButtonVariant} from ".";

const getAsyncIcon = (
  status: ButtonStatus,
): readonly [IconName | undefined, string | undefined] => {
  switch (status) {
    case ButtonStatus.LOADING:
      return ["logo", "text-white animate-spin"] as const;
    case ButtonStatus.SUCCESS:
      return ["check", ""] as const;
    default:
      return [undefined, undefined];
  }
};

const AsyncButton = ({
  label,
  loadingLabel,
  successLabel,
  size = ButtonSize.MD,
  onClick,
  disabled = false,
  className = "",
  resetTimeout,
  statusOverride,
}: {
  label: string;
  loadingLabel: string;
  successLabel: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  onClick: (event: React.MouseEvent) => Promise<unknown>;
  disabled?: boolean;
  className?: string;
  /**
   * Time in milliseconds after which the button will reset to its idle state after async fn completes.
   */
  resetTimeout?: number;
  /**
   * If the button can be put into a non-idle state by a parent component, for example submitting a form via `Enter` key, this prop can be used to override the button state.
   */
  statusOverride?: ButtonStatus;
}) => {
  const [state, setState] = React.useState(ButtonStatus.IDLE);

  const handleClickAndState = async (event: React.MouseEvent) => {
    setState(ButtonStatus.LOADING);
    try {
      await onClick(event);
      setState(ButtonStatus.SUCCESS);
    } catch (e) {
      setState(ButtonStatus.IDLE);
    }
  };

  useEffect(() => {
    if (statusOverride) {
      setState(statusOverride);
    }
  }, [statusOverride]);

  useEffect(() => {
    if (state === ButtonStatus.SUCCESS && resetTimeout) {
      const timeout = setTimeout(() => {
        setState(ButtonStatus.IDLE);
      }, resetTimeout);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [resetTimeout, state]);

  const [icon, iconClassName] = getAsyncIcon(state);

  return (
    <button
      onClick={disabled ? noOp : handleClickAndState}
      className={getAsyncButtonStyle(state, size, className)}
      disabled={disabled || state !== ButtonStatus.IDLE}
    >
      {state === ButtonStatus.LOADING
        ? loadingLabel
        : state === ButtonStatus.SUCCESS
        ? successLabel
        : label}
      {icon ? <Icon icon={icon} className={iconClassName} /> : null}
    </button>
  );
};

export default React.memo(AsyncButton);
